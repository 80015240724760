/* eslint-disable import/extensions */
import Vue from 'vue'
import axios from 'axios'
import { isFlag } from '~/plugins/feature-flags.js'
import { formatCurrency, optimizeImage, getThemeColors, hexToRGB } from '~/stan-vue-shared/components/utils.js'

const mobile = {
	isMobile() {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || width < 768
	},
	isIosDevice() {
		return typeof window !== 'undefined' &&
			window.navigator &&
			window.navigator.platform &&
			(/iP(ad|hone|od)/.test(window.navigator.platform) || (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1))
			? true
			: false
	},
}

const deviceId = {
	deviceUID() {
		const navigatorInfo = window.navigator
		const screenInfo = window.screen
		let uid = navigatorInfo.mimeTypes.length
		uid += navigatorInfo.userAgent.replace(/\D+/g, '')
		uid += navigatorInfo.plugins.length
		uid += screenInfo.height || ''
		uid += screenInfo.width || ''
		uid += screenInfo.pixelDepth || ''
		return uid
	},
}

const cardBrand = {
	check_card(cardClass) {
		let cardIcon = ''
		const stringData = cardClass.split('width-60 focus-visible')
		const cardBrand = stringData[1]
		if (cardBrand === ' visa identified') cardIcon = 'fab fa-cc-visa'
		else if (cardBrand === ' mastercard identified') cardIcon = 'fab fa-cc-mastercard'
		else if (cardBrand === ' amex identified') cardIcon = 'fab fa-cc-amex'
		else if (cardBrand === ' discover identified') cardIcon = ' fab fa-cc-discover'
		else if (cardBrand === ' diners identified') cardIcon = 'fab fa-cc-diners-club'
		else if (cardBrand === ' jcb identified') cardIcon = 'fab fa-cc-jcb'
		else cardIcon = 'fa fa-credit-card'
		return cardIcon
	},
}

// Optimize image for width and quality
const optimizeImageWrapper = (src, options = {}) => {
	return optimizeImage(src, options)
}

const addThemeColorsToDocument = (theme, primaryColorHex, secondaryColorHex, primaryFont) => {
	const themeColors = getThemeColors(theme, primaryColorHex, secondaryColorHex)
	document.documentElement.style.setProperty('--stan-store-primary-color', themeColors.primaryColor)
	document.documentElement.style.setProperty('--stan-store-theme-primary-color', themeColors.primaryColor)
	document.documentElement.style.setProperty('--stan-store-secondary-color', themeColors.secondaryColor)
	document.documentElement.style.setProperty('--stan-store-theme-secondary-color', themeColors.secondaryColor)
	document.documentElement.style.setProperty('--stan-store-theme-tone1-color', themeColors.tone1)
	document.documentElement.style.setProperty('--stan-store-theme-tone2-color', themeColors.tone2)
	document.documentElement.style.setProperty('--stan-store-theme-tone3-color', themeColors.tone3)
	document.documentElement.style.setProperty('--stan-store-theme-derived1-color', themeColors.derived1)
	document.documentElement.style.setProperty('--stan-store-theme-derived2-color', themeColors.derived2)
	document.documentElement.style.setProperty('--stan-store-theme-derived3-color', themeColors.derived3)
	document.documentElement.style.setProperty('--stan-store-theme-primary-text-color', themeColors.primaryTextColor)
	document.documentElement.style.setProperty('--stan-store-theme-button-text-color', themeColors.buttonTextColor)
	document.documentElement.style.setProperty('--stan-store-theme-secondary-text-color', themeColors.secondaryTextColor)
	document.documentElement.style.setProperty('--stan-store-theme-placeholder-text-color', themeColors.placeholderTextColor)

	if (primaryFont) document.documentElement.style.setProperty('--stan-store-theme-primary-font', primaryFont)

	return themeColors
}

const emptyImage =
	'data:image/gif;base64,R0lGODlhngHgAoABAAAAAP///yH5BAEKAAEALAAAAACeAeACQAL+jI+py+0Po5y02ouz3rz7D4biSJbmiabqyrbuC8fyTNf2jef6zvf+DwwKh8Si8YhMKpfMpvMJjUqn1Kr1is1qt9yu9wsOi8fksvmMTqvX7Lb7DY/L5/S6/Y7P6/f8vv8PGCg4SFhoeIiYqLjI2Oj4CBkpOUlZaXmJmam5ydnp+QkaKjpKWmp6ipqqusra6voKGys7S1tre4ubq7vL2+v7CxwsPExcbHyMnKy8zNzs/AwdLT1NXW19jZ2tvc3d7f0NHi4+Tl5ufo6err7O3u7+Dh8vP09fb3+Pn6+/z9/v/w8woMCBBAsaPIgwocKFDBs6fAgxosSJFCtavIgxo8b+jRw7evwIMqTIkSRLmjyJMqXKlSxbunwJM6bMmTRr2ryJM6fOnTx7+vwJNKjQoUSLGj2KNKnSpUybOn0KNarUqVSrWr2KNavWrVy7ev0KNqzYsWTLmj2LNq3atWzbun0LN67cuXTr2r2LN6/evXz7+v0LOLDgwYQLGz6MOLHixYwbO34MObLkyZQrW76MObPmzZw7e/4MOrTo0aRLmz6NOrXq1axbu34NO7bs2bRr276NO7fu3bx7+/4NPLjw4cSLGz+OPLny5cybO38OPbr06dSrW7+OPbv27dy7e/8OPrz48eTLmz+PPr369ezbu38PP778+fTr27+PP7/+/fz++/v/D2CAAg5IYIEGHohgggouyGCDDj4IYYQSTkhhhRZeiGGGGm7IYYcefghiiCKOSGKJJp6IYooqrshiiy6+CGOMMs5IY4023ohjjjruyGOPPv4IZJBCDklkkUYeiWSSSi7JZJNOPglllFJOSWWVVl6JZZZabslll15+CWaYYo5JZplmnolmmmquyWabbr4JZ5xyzklnnXbeiWeeeu7JZ59+/glooIIOSmihhh6KaKKKLspoo44+Cmmkkk5KaaWWXopppppuymmnnn4Kaqiijkpqqaaeimqqqq7KaquuvgprrLLOSmuttt6Ka6667sprr77+Cmywwg5LbLHGHotebLLKLstss84+C2200k5LbbXWXottttpuy2233n4Lbrjijktuueaei2666q7LbrvuvgtvvPLOS2+99t6Lb7767stvv/7+C3DAAg9McMEGH4xwwgovzHDDDj8MsR0FAAA7'

const formatCurrencyWrapper = (amount, currency = 'USD', locale = 'en-US', longSymbolUs = false) => {
	return formatCurrency(amount, currency, locale, longSymbolUs)
}

const sessionStorageWrapper = {
	key(index) {
		try {
			return sessionStorage && sessionStorage.key(index)
		} catch(e) {
			console.log("sessionStorage key()", e)
			console.error(e)
		}
		return -1
	},
	setItem(key, value) {
		try {
			sessionStorage && sessionStorage.setItem(key, value)
		} catch(e) {
			console.log("sessionStorage setItem()", e)
			console.error(e)
		}
	},
	getItem(key, defaultVal = null) {
		try {
			return sessionStorage && sessionStorage.getItem(key)
		} catch(e) {
			console.log("sessionStorage getItem()", e)
			console.error(e)
		}
		return defaultVal

	},
	removeItem() {
		try {
			return sessionStorage && sessionStorage.removeItem()
		} catch(e) {
			console.log("sessionStorage removeItem()", e)
			console.error(e)
		}
	},
	clear() {
		try {
			return sessionStorage && sessionStorage.clear()
		} catch(e) {
			console.log("sessionStorage clear()", e)
			console.error(e)
		}
	}
}

const helpers = {
	appendCurrentUrlParamsToUrl: (url) => {
		const linkUrl = new URL(url)
		// Carry any params over to other stan pages. Only done for Stan pages to avoid sideeffects from other websites ex. the site doesn'tload since we added url params
		if (linkUrl && linkUrl.href.includes('stan.store')) {
			const targetParams = new URLSearchParams(linkUrl.search);
			const currentUrlParams = new URLSearchParams(window.location.search)
			currentUrlParams.forEach((value, key) => {
				if (!targetParams.has(key)) {
					targetParams.append(key, value);
				}
			});
			linkUrl.search = targetParams.toString();
		}

		return linkUrl
	},
	async getProductsForPaymentId($axios, username, details = {}) {
		const out = {
			course: undefined,
			products: undefined
		}
		try {
			const payload = {
				username,
			}
			if (details?.checkout_session_id) {
				payload['checkout_session_id'] = details.checkout_session_id
			}
			if (details?.id) {
				payload['stripe_id'] = details.id
			}
			if (details?.payment_intent) {
				payload['stripe_id'] = details.payment_intent
			}
			if (details?.order_id) {
				payload['order_id'] = details.order_id
			}

			let url = null 
			if (payload['order_id']) {
				url = `/v1/pages/order-success`
			} else if (payload['checkout_session_id'] || payload['stripe_id']) {
				url = `/v1/pages/success`
			}

			if (url) {
				const response = await $axios.post(url, payload)
				if (response.data?.course) {
					out.course = response.data.course
				}
				if (response.data?.products) {
					out.products = response.data.products
				}
			}
		} catch (err) {
			console.error('error: ', err)
		}

		return out
	}
}

// eslint-disable-next-line require-await
export default async ({ $axios }, inject) => {
	inject('card_brand', cardBrand)
	inject('$emptyImage', emptyImage)
	inject('optimizeImage', optimizeImageWrapper)
	inject('sessionStorage', sessionStorageWrapper)
	inject('mobile', mobile)
	inject('formatCurrency', formatCurrencyWrapper)
	inject('hexToRGB', hexToRGB)
	inject('addThemeColorsToDocument', addThemeColorsToDocument)
	inject('helpers', helpers)
	
	Object.defineProperty(Vue.prototype, '$emptyImage', { value: emptyImage })
}
